import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="CURSURI" items={items}>
      <SEO title="Cursuri EBE" keywords={[`cursuri`, 'carte creștină']} />
      <P>
        <b>Cursuri EBE disponibile</b>
      </P>

      <P>
        <ul>
          <li>Apologetica – manual + ghid</li>
          <li>Arta comunicării adevărului biblic</li>
          <li>Calatorie prin V.T.- manual (Schultz) + ghid îndrumător</li>
          <li>Cheile consilierii biblice</li>
          <li>Comentariu al Vechiului Testament</li>
          <li>Comentariu Noul Testament (Walvoord & Zuck)</li>
          <li>Comunicarea Cuvântului – manual + ghid</li>
          <li>Creșterea bisericii pentru gloria lui Dumnezeu</li>
          <li>Creșterea copiilor – manual + ghid</li>
          <li>Cum să conduci un grup mic de studiu biblic</li>
          <li>Cum să studiem Biblia – manual + ghid</li>
          <li>Descoperind Biblia prin studiu</li>
          <li>Dinamica bisericii – manual + Dinamica Bisericii – Getz</li>
          <li>
            Dobândirea unei inimi înțelepte & Dobindirea unei inimi înțelepte –
            ghid
          </li>
          <li>Doctrine I – manual + ghid</li>
          <li>Evanghelizarea ca mod de viață + ghid + manual</li>
          <li>Făuritorii căsniciei</li>
          <li>Galateni și Romani – manual + ghid îndrumător + ghid student</li>
          <li>Iată Dumnezeul tău</li>
          <li>Împlinește-mi sufletul însetat</li>
          <li>Închinarea</li>
          <li>Înțelegând oamenii</li>
          <li>Liniștește-mi inima îngrijorată</li>
          <li>Lupta</li>
          <li>Manualul Facilitatorului</li>
          <li>Măsura unui om</li>
          <li>Misiologie – vol. I, II și III</li>
          <li>Motive suficiente</li>
          <li>
            Noul Testament, o privire de ansamblu – manual + ghid indrumator+
            ghid student
          </li>
          <li>O viață care schimbă lumea</li>
          <li>Partenera creativă</li>
          <li>Păstorire eficientă</li>
          <li>Păstorul ucenicizator + Pastorul ucenicizator – ghid</li>
          <li>Portretul predicatorului</li>
          <li>Practicarea evlaviei</li>
          <li>Predarea care schimbă vieți</li>
          <li>Principii de conducere</li>
          <li>Refocalizarea liderilor</li>
          <li>Să ne zidim unii pe alții</li>
          <li>Să știi de ce crezi</li>
          <li>Teologie elementară</li>
          <li>Trăirea dupa Biblie</li>
          <li>Ucenicii se fac nu se nasc</li>
          <li>
            Ucenicie în școala lui Isus (Col 2:7 – Vol. 1 și 2+ Col.2:7 – ghid
          </li>
          <li>Uneltele pentru evanghelizarea personală</li>
          <li>Vechiul Testament – manual + ghid îndrumător + ghid student</li>
          <li>Viața creștină – manual + ghid</li>
          <li>Viața de dragoste pentru cuplu căsătorit</li>
          <li>Vocația mamei spirituale</li>
        </ul>
      </P>
    </PageWithLeftMenu>
  )
}
